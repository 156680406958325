

/* ----------------------------------------
        Imports
    ---------------------------------------- */

import { Component, Vue } from 'vue-property-decorator';

import PageMain from '@part/layout/PageMain.vue';
import ForgotForm from '@part/auth/ForgotForm.vue';

/* ----------------------------------------
        Component
    ---------------------------------------- */

    @Component({
        components: {
            PageMain,
            ForgotForm,
        },
    })

/* ----------------------------------------
        Export
    ---------------------------------------- */

export default class LoginView extends Vue {}




/* ----------------------------------------
        Imports
    ---------------------------------------- */

import { Component, Prop, Vue } from 'vue-property-decorator';
import ErrorResponseMixin from '@mixin/errorResponse';

/* ----------------------------------------
        Component
    ---------------------------------------- */

    @Component({
        data() {
            return {
                email: ''
            };
        },
        mixins: [
            ErrorResponseMixin,
        ],
        methods: {
            submit() {
                this.$store.dispatch('user/forgot', {
                    email: this.email,
                    url: `${ window.location.origin }/reset`
                }).then(response => {
                    if (response instanceof Error) {
                        if (response.response.status == 422){
                            this.$store.dispatch('setToastMessage', this.getErrorMessage(response))
                            this.$store.dispatch('setToastButton', '');
                        } else {
                            this.$store.dispatch('setToastMessage', response.response.data.message)
                            this.$store.dispatch('setToastButton', '');
                        }
                        return;
                    }

                    this.$store.dispatch('setToastMessage', 'Check je mailbox voor een reset link.')
                    this.$store.dispatch('setToastButton', '');
                    this.$router.push({
                        path: '/login',
                    });
                });
            }
        },
    })

/* ----------------------------------------
        Export
    ---------------------------------------- */

export default class ForgotForm extends Vue {}

